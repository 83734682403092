import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import Title from "../components/title"

const DspPage = ({ data, location, lang }) => (
  <Layout location={location} lang="fi">
    <SEO title="Tietoturvakäytäntö" keywords={['Tietoturvakäytäntö', 'GDPR']} />
    <TopSection img={data.img.childImageSharp.fluid} title="SH-Maalaus OY" styleClass="proposal-background" lang="fi" />
    <div className="container py-5">
        <Title title="Tietoturvakäytäntö" />
        <p className="">
            Tämä tietosuojaseloste selittää miten SH-Maalaus (y-tunnus 2103924-6) käsittelee henkilötietoja, EU:n tietosuoja-asetuksen 2016/679 (GDPR) mukaan. SH-Maalaus on vastuussa henkilötietojen käsittelystä toiminnassaan.
        </p>
        <h5>Mitä henkilötietoja käsitellään</h5>
        <p className="">
          SH-Maalaus käsittelee henkilötietoja, jotka välitetään suoraan tai epäsuorasti yhteydenoton, asiakas- tai toimittajasuhteen tai verkkosivuvierailun myötä. Kyseessä on useimmiten henkilö- ja yhteystiedot, kuten nimi, osoite, sähköposti ja puhelinnumero. IP-osoitetta, laitetietoa, alustaa, kieliasetusta, maantieteellistä ja demografista asennoitumista voidaan myös käsitellä. Tietoja verkkosivuston käyttötavasta kerätään evästeiden avulla, lisätietoja varten katso evästekäytäntömme.
        </p>
        <h5>Mihin henkilötiedot käytetään</h5>
        <p className="">
          Me käsittelemme henkilötietoja lähinnä asiakassuhteiden ja toimitussuhteiden ylläpitämiseksi, yhteydenottojen hoitamiseksi esimerkiksi tiedusteluja varten ja lainsäädännön noudattamista varten. Tietoja käsitellään myös markkinointi-, myynti- ja tilastotarkoituksiin, muun muassa toiminnan valvontaa ja kehittämistä varten.
          Meidän käsittelemät henkilötiedot noudattavat yhtä tai useampaa edellä mainituista tarkoituksista. Käsittelylle tulee olla laillinen peruste, kuten suostumus, sopimus tai lakisääteinen velvoite. Ainoastaan tiettyyn tarkoitukseen tarvittavia henkilötietoja käsitellään.
        </p>
        <h5>Kenen kanssa jaamme henkilötietoja</h5>
        <p className="">
          SH-Maalaus saattaa jakaa tietoja valittujen kolmansien osapuolten kanssa valvotuissa muodoissa, jotta tietojasi käsitellään turvallisesti. Nämä kolmannet osapuolet voivat olla toimittajia. Tietoja ei myydä eikä jaeta ilman perusteita.
        </p>
        <h5>Missä henkilötietoja käsitellään</h5>
        <p className="">
          Me käsittelemme henkilötietoja ainoastaan EU/EES:n sisällä.
        </p>
        <h5>Kuinka kauan säilytämme henkilötietoja</h5>
        <p className="">
          SH-Maalaus säilyttää henkilötietoja ainoastaan niin kauan, kuin niitä tarvitaan voidaksemme täyttää velvollisuutemme sinua kohtaan, ja niin kauan kuin tietoja tulee säilyttää säilytysaikojen ja muiden lainmukaisten käyttötarkoitusten mukaan, kuten kirjanpito.
        </p>
        <h5>Miten henkilötietoja suojataan</h5>
        <p className="">
          Suojaamme henkilötietoja asianmukaisilla teknisillä ja käytönnön turvatoimilla. Pääsyä rajoitetaan esimerkiksi siten, että ainoastaan tarvitsevat henkilöt pääsevät tietoihin sisäänkirjautumisen kautta.
        </p>
        <h5>Oikeudet</h5>
        <p className="">
          EU:n tietosuoja-asetuksen mukaan sinulla on oikeus saada pääsy henkilötietoihin, joita SH-Maalaus sinusta käsittelee, ja mahdollisuus oikaista tiedot, mikäli ne ovat virheellisiä. Sinulla on myös oikeus pyytää tulla poistetuksi järjestelmästämme, jos tietoja ei enää tarvita siihen tarkoitukseen mihin ne kerättiin. Joissakin tapauksissa lainsäädäntö voi vaatia, että tieto jää, esimerkiksi kirjanpito- tai verotarkoituksiin, jolloin tietoja voidaan rajoittaa olemaan käytettävissä ainoastaan näihin tarkoituksiin. Mikäli tietoja käsitellään suostumuksesi perusteella, sinulla on oikeus peruuttaa suostumuksesi. Sinulla on myös oikeus valittaa valvontaviranomaiselle, mikäli koet, että emme ole noudattaneet sovellettavaa tietosuojasäännöstöä. Katso alla olevat yhteystiedot koskien henkilötietojesi käsittelyä meillä.
        </p>
        <h5>Yhteystiedot</h5>
        <p className="">
           Kysymykset koskien SH-Maalauksen henkilötietoselostetta osoitetaan seuraavaan sähköpostiosoitteeseen: sven.hallmark@shmaleri.fi
        </p>
    </div>
  </Layout>
)

export const query = graphql`
  {
    img: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }`

export default DspPage